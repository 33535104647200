@charset 'UTF-8';
@import './setting/variables';

.C0036 {
	max-width:(1284px+60px);
	padding-left:30px;
	padding-right:30px;
	@include screen(custom, max, $max-sm) {
		padding:0 24px
	}
	.title-wrap {
		width:100%;
		padding:13px 30px 0;
		max-width:(1284px+60px);
		margin:0 auto 44px;
		@extend %clearfix;
		@include screen(custom, max, ($max-lg)) {
			padding-top:15px;
		}
		@include screen(custom, max, ($max-sm)) {
			padding:33px 24px 0;
			margin-bottom:35px;
		}
		.inner {
			max-width:800px;
		}
		&.align-left {
			.inner {
				float:left;
				text-align:left;
			}
		}
		&.align-right {
			.inner {
				float:right;
				text-align:right;
			}
		}
		&.align-center {
			.inner {
				margin:0 auto;
				text-align:center;
			}
		}
		.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6 {
			color:$color-black;
			font-size:48px;
			line-height:56px;
			@include font-family($font-semibold);
			@include screen(custom, max, $max-lg) {
				font-size:44px;
				line-height:52px;
			}
			@include screen(custom, max, $max-md) {
				font-size:40px;
				line-height:48px;
			}
			@include screen(custom, max, ($max-sm)) {
				font-size:32px;
				line-height:36px;
			}
		}
		.title {
			color:$color-black;
			max-width:1284px;
			margin:0 auto;
		}
	}
	.products-tab-area {
		margin-bottom: 42px;
		@include screen(custom, max, $max-sm) {
			margin-bottom: 22px;
		}
	}
	.heading-box {
		margin-bottom: 15px;
		text-align: left;
		@include screen(custom, max, $max-sm) {
			margin-bottom:6px;
		}
		.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6 {
			font-size:24px;
			line-height:32px;
			@include font-family($font-semibold);
			color:$color-black;
			@include screen(custom, max, $max-sm) {
				font-size:20px;
				line-height:26px;
			}
		}
	 }
	.info-link{
		margin-bottom: 29px;
		.link-text {
			color: $color-dimgray;
			&:after {
				margin-left: 1px;
				background: url(/lg5-common/images/common/icons/arrow-right-dimgray.svg) no-repeat 0 0;
				background-size: 15px;
			}
		}
		@include screen(custom, max, $max-sm) {
			margin-bottom: 19px;
		}
	}
	.notice-detail {
		margin-bottom: 30px;
		border-top: 1px solid $line-lightgray;
		border-bottom: 1px solid $line-lightgray;
	}
	.btn-area {
		text-align: right;
		@include screen(custom, max, $max-sm) {
			text-align: center;
		}
	}

	.title-area {
		position: relative;
		padding: 35px 30px 35px 30px;
		border-bottom: 1px solid $line-lightgray;
		@include screen(custom, max, $max-sm) {
			padding:25px 0;
		}
		.title {
			font-size:24px;
			line-height:32px;
			@include font-family($font-semibold);
			color:$color-black;
			text-align: left;
			@include screen(custom, max, $max-sm) {
				font-size:20px;
				line-height:26px;
			}
		}
	 }
	 .control-area {
		position:absolute;
		right:31px;
		bottom:20px;
		text-align:right;
		font-size:0;
		@include screen(custom, max, $max-sm) {
			position:static;text-align:left;
		}
		.control {
			display:inline-block;
			a {
				display:inline-block;
				position:relative;
				margin:0 0 0 22px;
				padding:0 0 0 30px;
				@include font-family($font-bold);
				font-size:14px;
				line-height:20px;
				color:$color-dimgray;
				text-transform:uppercase;
				text-decoration:none;
				@include screen(custom, max, $max-sm) {
					margin:0 15px 0 0;
				}
			}
			.link-download {
				&:before {
					@include pseudo();
					position:absolute;
					left:0;top:0;
					width:20px;
					height:20px;
					background:url('/lg5-common/images/common/icons/download-large.svg') no-repeat 0 0;
				}
				@include screen(custom, max, $max-sm) {
					padding-bottom:20px;
				}
			}
			.page-print {
				&:before {
					@include pseudo();
					position:absolute;
					left:0;top:0;
					width:20px;
					height:20px;
					background:url('/lg5-common/images/common/icons/print-dimgray.svg') no-repeat 0 0;
				}
				@include screen(custom, max, $max-sm) {
					display:none;
				}
			}
		}
		@include screen(custom, max, $max-sm) {
			.no-download + .sns-area .list {
				right:-156px;
				&:before {
					right:172px;
				}
			}
		}
		.sns-area {
			display:inline-block;
			position:relative;
			margin:0 0 0 22px;
			@include screen(custom, max, $max-sm) {
				margin:0;padding-bottom:20px;
			}
			.sns {
				display:block;
				height:20px;
				border:0;
				padding:0 0 0 28px;
				font-size:14px;
				line-height:20px;
				@include font-family($font-bold);
				text-transform:uppercase;
				color:$color-dimgray;
				background:url('/lg5-common/images/common/icons/sns-share.svg') no-repeat 0 0;
				cursor:pointer;
			}
			.list {
				display:none;
				position:absolute;right:0px;top:30px;z-index:1;
				width:226px;
				padding:8px 0 0;
				@include screen(custom, max, $max-sm) {
					right:-87px;
				}
				&.active {
					display:block;
				}
				&:before {
					position: absolute;top:2px;right:15px;
					content:'';
					border-color: $line-gray;
					border-style: solid;
					border-width: 0 0 1px 1px;
					background: $bg-white;
					width: 11px;
					height: 11px;
					transform: rotate(135deg);
					@include screen(custom, max, $max-sm) {
						right:105px;
					}
				}
				.sns-inner {
					padding:16px 24px 24px;
					border:1px solid $line-gray;
					border-radius:2px;
					background:$bg-white;
					text-align:center;
					box-shadow:0 5px 10px rgba(0,0,0,0.1);
					strong {
						display:block;
						text-align:left;
						color:$color-nightrider;
						@include font-family($font-regular);
						font-size:14px;
						line-height:20px;
					}
					ul {margin:10px 0 0;font-size:0;}
					li {
						display:inline-block;
						&:first-child a {margin-left:0;}
						a {
							display:inline-block;
							width:35px;height:35px;
							margin:0 0 0 12px;
							overflow:hidden;text-indent:200%;white-space:nowrap;
							font-size:12px;
							&.share-facebook {
								background:url('/lg5-common/images/common/icons/facebook-circle-fill.svg') no-repeat 0 0;
							}
							&.share-twitter {
								background:url('/lg5-common/images/common/icons/twitter-circle-fill.svg') no-repeat 0 0;
							}
							&.share-pinterest {
								background:url('/lg5-common/images/common/icons/pinterest-circle-fill.svg') no-repeat 0 0;
							}
							&.article-email {
								background:url('/lg5-common/images/common/icons/email-circle-fill.svg') no-repeat 0 0;
							}
						}
					}
				}
			}
		}
	}

	.date {
		margin: 9px 0 0 0;
		font-size:14px;
		line-height: 20px;
		color: $color-gray;
		@include screen(custom, max, $max-sm) { 
			@include font-family($font-semibold);
		}
	}
	.contents {
		padding: 34px 160px 28px 30px;
		font-size: 16px;
		line-height: 24px;
		color: $color-dimgray;
		text-align: left;
		p {margin:0;}
		img {max-width: 100%;}
		@include screen(custom, max, $max-sm) {
			padding: 24px 0 34px 0;
			color: $color-dimgray;
		}
		b, strong {
			@include font-family($font-semibold);
		}
	}

	.article-nav {
		margin-top:30px;
		border-top:1px solid $line-gray;
		border-bottom:1px solid $line-gray;
		.nav-button {
			padding:0 0 0 30px;
			/* PJTWAUS-1 20200114 modify */
			margin-bottom:0;
			@extend %clearfix;
			/* //PJTWAUS-1 20200114 modify */
			//LGEGMO-5525 add
			//@extend %listreset;
			dt{
				display:inline-block;
			}
			dd{
				display: inline-block;
				width:85%;
				@extend %listreset;
				@include screen(custom, max, $max-sm) {
					width:95%;
				}
			}
			//LGEGMO-5525 add
			
			/* PJTWAUS-1 20200114 modify */
			&:after {
				content:'';
				display:block;
				clear:both;
			}
			dt {
				float:left;
				span {
					position:relative;
					font-size:16px;
					line-height:28px;
					color:$color-nightrider;
					@include font-family($font-semibold);
					padding-right:20px;
					padding-top:14px;
					padding-bottom:13px;
					margin-right:20px;
					width:78px;
					display:inline-block;
					vertical-align:top;
					@include screen(custom, max, $max-sm) {
						display:inline-block;
						padding:19px 0 20px 0;
						width:77px;
						line-height:18px;
						position: absolute;
						top: 50%;
						transform: translate(0,-50%);
					}
					&:after {
						@include pseudo();
						right:0;
						top:50%;
						width:1px;
						height:15px;
						background:$bg-nobel; 
						transform:translateY(-50%);
					}
				}
			}
			dd {
				float:left;
				width:calc(100% - 100px); 
				margin-bottom:0;
				a {
					display: inline-block;
					font-size:16px;
					line-height:28px; 
					@include font-family($font-regular);
					color:$color-dimgray;
					width:85%;
					padding-top:14px;
					padding-bottom:13px;
					vertical-align:top;
					@include screen(custom, min, $min-md) {
						@include truncate(100%);
					}
					@include screen(custom, max, $max-sm) {
						width:95%;
						line-height:17px;
						padding:0;  /* //PJTWAUS-1 20191226 modify  */
						padding-left: 97px;
						overflow:hidden;
						text-overflow: ellipsis;
						display:-webkit-box;
						-webkit-line-clamp:2;
						-webkit-box-orient:vertical;
						position: absolute;
						top: 50%;
						transform: translate(0,-50%);
					}
				}
			}
			/* //PJTWAUS-1 20200114 modify */		
			
			
			@include screen(custom, max, $max-sm) {
				padding:0;
				height:56px;
				position: relative;
			}
			span {
				position:relative;
				font-size:16px;
				line-height:28px;
				color:$color-nightrider;
				@include font-family($font-semibold);
				padding-right:20px;
				padding-top:14px;
				padding-bottom:13px;
				margin-right:20px;
				width:78px;
				display:inline-block;
				vertical-align:top;
				@include screen(custom, max, $max-sm) {
					display:inline-block;
					padding:19px 0 20px 0;
					width:77px;
					line-height:18px;
					position: absolute;
					top: 50%;
					transform: translate(0,-50%);
				}
				&:after {
					@include pseudo();
					right:0;
					top:50%;
					width:1px;
					height:15px;
					background:$bg-nobel;
					transform:translateY(-50%);
				}
			}
			a {
				display: inline-block;
				font-size:16px;
				line-height:28px; 
				@include font-family($font-regular);
				color:$color-dimgray;
				//LGEGMO-5525 modify
				width:100%;
				//LGEGMO-5525 modify
				padding-top:14px;
				padding-bottom:13px;
				vertical-align:top;
				@include screen(custom, min, $min-md) {
					@include truncate(100%);
				}
				@include screen(custom, max, $max-sm) {
					//width:95%;//LGEGMO-5525 modify
					line-height:17px;
					padding:19px 0 20px 0;
					padding-left: 97px;
					overflow:hidden;
					text-overflow: ellipsis;
					display:-webkit-box;
					-webkit-line-clamp:2;
					-webkit-box-orient:vertical;
					position: absolute;
					top: 50%;
					transform: translate(0,-50%);
				}
			}
			&:first-child {
				border-bottom:1px solid $line-lightgray;
			}
			&:only-child {
				border-bottom:0;
			}
		}
	}

}
